import { computed, inject, Injectable, signal } from '@angular/core';
import { GthAuthService } from '@gth-legacy';
import { LoginType } from '@index/enums';
import { LoginContract } from '@index/interfaces/login';

import { SharedAuthService } from '../../../services/shared-auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private auth = inject(GthAuthService);
  private sharedAuthService = inject(SharedAuthService);

  loading = signal(false);
  combinedLoading = computed(() => this.loading() || this.sharedAuthService.loading());

  get rememberedEmail() {
    return this.auth.getStoredUserName();
  }

  public onLogin(contract: LoginContract) {
    const validBrowser = this.sharedAuthService.isBrowserValid(contract.loginType);
    if (!validBrowser) {
      return;
    }
    this.loading.set(true);
    switch (contract.loginType) {
      case LoginType.Email:
        this.loginWithEmail(contract);
        break;
      case LoginType.Google:
        this.loginWithGoogle();
        break;
      default: break;
    }
    this.loading.set(false);
  }

  private loginWithEmail(contract: LoginContract) {
    if (contract.rememberMe) {
      this.auth.setStoredUserName(contract.email);
    }
    this.auth.storeEmailLogin(contract.email, contract.password);
  }

  private loginWithGoogle() {
    this.auth.storeGoogleLogin();
  }
}
