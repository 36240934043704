import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnsafeBrowserService {
  private unsafeSubject = new BehaviorSubject<boolean>(false);

  get unsafe$() {
    return this.unsafeSubject.asObservable();
  }

  constructor(private clipboard: Clipboard) { }

  markUnsafe() {
    this.unsafeSubject.next(true);
  }

  copyUrl(route: string): void {
    this.clipboard.copy(route);
    if (this.clipboard.copy(route)) {
      console.log('URL copied to clipboard');
    }
  }
}
